import './sprite'
import ShareImg from './shareImg'
import Share from "./share"

$(() => {
  const share = new Share();
  const shareImg = new ShareImg();
  const $vkBtn = $('.js-vkontakte-btn');
  const $shareBtn =  $('.js-share');

  $vkBtn.on('click', function (e) {
    // e.preventDefault();
    shareImg.render()
  });

  $shareBtn.on('click', function () {
    share.render()
    shareImg.render()

    setTimeout(() => {
      $('.results__btn-secondary-wrap').addClass('hidden')
      $('.results__btn-wrap').removeClass('hidden')
    }, 500)
  });

  $('.js-result-input').on('change', function () {
    setImgName()
    $('.results__btn-wrap').addClass('hidden')
    $('.results__btn-secondary-wrap').removeClass('hidden')
  })

  function setImgName() {
    let imgName = '';
    $('.js-result-input').each(function (){
      imgName += $(this).prop('checked')? '1' : '0'
    })

    const dataLink = `/assets/i/share/${imgName}.png`;

    $('.js-download').attr('href', dataLink)
    $('[data-share]').attr('data-image', dataLink).attr('data-code', imgName);

    getImgList()
  }

  function getImgList() {
    let images = '';
    $('.js-result-input').each(function (index){
      const imgPath = $(this).prop('checked')? 'blue' : 'white'
      images += `<img src="/assets/i/source/${imgPath}/${index+1}.png" class="js-result-img" alt="">`;
    })
    $('.js-cards-images').html(images);
  }
})
