import Share from "./share";

export default class {
  constructor(options) {
    const defaults = {
      el: '[data-canvas-result]'
    };

    this.options = $.extend(true, {}, defaults, options);
    this.$el = (this.options.el instanceof $) ? this.options.el : $(this.options.el);
    return this;
  }

  get() {
    this.$el = $(this.$el);
    return this;
  }

  init() {
    this.updateImageBlob();
  }

  updateImageBlob() {
    const canvas = document.getElementById("drawingCanvas");
    const context = canvas.getContext("2d");
    const width = this.$el.width();
    const height= this.$el.height();
    const imgWidth = 154;
    const imgHeight = 104;
    let imgTop = 133;
    let imgLeft = 263;

    let imgMass = [];
    let imgName = '';

    $(document).find('.js-cards-images').each(function () {
      imgName = '';
      $('.js-result-input').each(function (){
        imgName += $(this).prop('checked')? '1' : '0'
      })

      const list = document.querySelectorAll('.js-result-img')

      list.forEach(function (item) {
        imgMass.push(item);
      });
    });

    let img = new Image();

    img.onload = function() {
      context.clearRect(0, 0, width, height);
      context.fillRect(0,0, width, height);
      //context.translate(-imageCenter[0], -imageCenter[1]);
      context.drawImage(img, 0, 0);

      imgMass.forEach(function (value, index) {

        switch (index) {
          case 0:
            imgLeft = 263;
            break;
          case 4:
            imgLeft = 262.5;
            imgTop = 247;
            break;
          case 8:
            imgLeft = 263;
            imgTop = 360;
            break;
          case 12:
              imgLeft = 263;
              imgTop = 473;
              break;
        }

        context.drawImage(value, imgLeft, imgTop, imgWidth, imgHeight);
        imgLeft += 173;
      });

     const imageBlob = dataURLToBlob(canvas.toDataURL('image/jpeg'));

      uploadCanvasData(imageBlob, imgName);
    };

    img.src = this.$el.attr('data-src');
  }

  render() {
    if (this.$el.length) {
      this
        .get()
        .init();
    }
  }
}

let dataURLToBlob = function(dataURL) {
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    let parts = dataURL.split(',');
    let contentType = parts[0].split(':')[1];
    let raw = parts[1];

    return new Blob([raw], {type: contentType});
  }

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(':')[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;

  let uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
};

let uploadCanvasData = function(imageBlob, name) {

  const data = new FormData();
  // name = 'sample-share';
  data.append('imageData', imageBlob);
  data.append('imageName', name);

  $(window).trigger('share.bindEvents');

  $.ajax({
    url: '/api/share-img/',
    data: data,
    cache: false,
    contentType: false,
    processData: false,
    type: 'POST',
    error: function(xhr, status, error) {
      //let err = eval("(" + xhr.responseText + ")");
      console.log(status);
    },
    complete: function() {
      console.log('ajax finish');
    }
  });
};
